import { createSlice } from "@reduxjs/toolkit";
// import { closeSystem } from "../actions/globals";
import { User } from "../../interfaces/ServerInterfaces";

interface InitialState {
  key: { token: string; refresh_token: string } | null;
  businessId: number | null;
  staticBar: boolean;
  user: User | null;
  currentCurrency: string | null;
  minimun_amount_to_buy_with_delivery: { amount: number, codeCurrency: string }[];
  availableCurrencies: Array<{
    id: number;
    exchangeRate: number;
    isMain: boolean;
    name: string;
    code: string;
    symbol: string
  }>
}

const initialState: InitialState = {
  key: null,
  businessId: null,
  staticBar: true,
  user: null,
  currentCurrency: null,
  minimun_amount_to_buy_with_delivery: [],
  availableCurrencies: []
};

const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setKeys: (state, action) => ({ ...state, key: action.payload }),
    setCurrentCurrency: (state, action) => ({ ...state, currentCurrency: action.payload }),
    setUser: (state, action) => ({ ...state, user: action.payload }),
    setBusinessId: (state, action) => ({
      ...state,
      businessId: action.payload,
    }),
    set_minimun_amount_to_buy_with_delivery: (state, action) => ({
      ...state,
      minimun_amount_to_buy_with_delivery: action.payload,
    }),
    set_availableCurrencies: (state, action) => ({
      ...state,
      availableCurrencies: action.payload,
    }),
    changeStaticBar: (state) => ({ ...state, staticBar: !state.staticBar }),
  },
  //   extraReducers: (builder) =>
  //     builder.addCase(closeSystem, (state) => ({ ...state, key: null })),
});

export const { setKeys, setUser, setBusinessId, changeStaticBar, setCurrentCurrency, set_minimun_amount_to_buy_with_delivery, set_availableCurrencies } =
  sessionSlice.actions;
export default sessionSlice.reducer;
