import query from "./APIServer";

import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { useState } from "react";
import { toast } from "react-toastify";
import { setKeys, setUser } from "../store/slices/SessionSlice";
import { closeSystem } from "../store/actions/globals";
import { useServerOrders } from "./useServerOrders";

export const useServer = () => {
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const { getMyOrders } = useServerOrders();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const manageErrors = (error: any) => {
    if (error.status === 401) {
      toast.error(error.response?.data?.message);
      // navigate("/");
    }
    if (error.response?.data?.message) {
      toast.error(error.response?.data?.message);
      return;
    } else {
      toast.error(
        "Upss, ha ocurrido un error inesperado. \n Intente de nuevo o consulte con su administrador..."
      );
      return;
    }
  };

  const logIn = async (
    data: Record<string, string | number | boolean>,
    onClick?: Function
  ) => {
    setIsFetching(true);
    await query
      .post("/security/login", data)
      .then((resp) => {
        getUser();
        dispatch(setKeys(resp.data));
        onClick!();
        getMyOrders({ per_page: 5 });

        toast.success(`Bienvenido`);
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const getUser = async () => {
    setIsFetching(true);
    await query
      .get("/shop/user")
      .then((resp) => {
        dispatch(setUser(resp.data));
      })
      .catch(
        (e) => manageErrors(e)
        // setError(e.message.data ?? "Ha ocurrido un error vuelva a intentarlo")
      );

    setIsFetching(false);
  };

  const logOut = async () => {
    setIsFetching(true);
    await query
      .post("/security/logout", {})
      .then((data) => {
        if (data.status === 204) {
          dispatch(setKeys(null));
          dispatch(setUser(null));
          dispatch(closeSystem(null));

          toast.success(`La cuenta ha sido cerrada satisfactoriamente`);
        }
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const registerUser = async (body: any, closeModal: Function) => {
    setIsFetching(true);
    const data: Record<string, any> = body;

    const { email, password } = data;

    await query
      .post("/shop/user/register", data)
      .then((resp) => {
        const response = JSON.parse(resp.data);
        toast.success(
          `La cuenta ${response.email} ha sido creada satisfactoriamente`
        );
        setTimeout(() => {
          logIn({
            username: email,
            password,
          });
          closeModal();
        }, 2000);
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const requestPasswordCodeRecovery = async (
    data: Record<string, string | number | boolean>
  ) => {
    setIsFetching(true);

    await query
      .post("/shop/user/requestCodePassword", data)
      .then(() => {
        toast.success(
          "Le hemos enviado un código a su correo electrónico. \n porfavor revíselo...",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        setTimeout(() => {
          navigate("?enterCode");
        }, 5000);
      })
      .catch((e) => {
        manageErrors(e);
      });

    setIsFetching(false);
  };

  const changePasswordViaCode = async (body: any, closeModal: Function) => {
    setIsFetching(true);
    const data: Record<string, any> = body;
    await query
      .post("/shop/user/changepassword", data)
      .then(() => {
        toast.success(`La contraseña ha sido cambiada con éxito`);
        setTimeout(() => {
          navigate("?login");
        }, 3000);
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const editMyUserClient = async (data: any) => {
    setIsFetching(true);
    await query
      .patch("/shop/user", data)
      .then((resp) => {
        toast.success("Información de perfil actualizada con éxito", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        dispatch(setUser(resp.data));
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  return {
    logIn,
    logOut,
    isFetching,
    manageErrors,
    registerUser,
    requestPasswordCodeRecovery,
    changePasswordViaCode,
    editMyUserClient,
  };
};

export default useServer;
