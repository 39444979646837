import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Formik } from "formik";
import MyTextInput from "./commos/MyTextInput";
import MyTextarea from "./commos/MyTextArea";

interface Props {
    onClick: Function;
    onSendWhatsApp: Function;
}

export default function ModalCheckoutForm({ onClick, onSendWhatsApp }: Props) {
    return (
        <Transition.Root show={true} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-40"
                onClose={() => onClick()}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-60"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-20"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 backdrop-blur-md backdrop-filter bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 top-24 px-3 overflow-y-auto">
                    <div className="flex min-h-auto items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative  rounded-md flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => onClick()}
                                    >
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    </button>

                                    <div className="sm:p-3 lg:p-8 flex-1">
                                        <div className="mt-5 md:mt-0 md:col-span-2">
                                            <Formik
                                                initialValues={{
                                                    name: "",
                                                    description: "",
                                                    address: "",
                                                }}
                                                onSubmit={async values => {
                                                    onSendWhatsApp(values);
                                                }}
                                            >
                                                {() => (
                                                    <Form>
                                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                                            <div className="flex flex-col p-3 gap-6 bg-white sm:p-6 ">
                                                                <MyTextInput
                                                                    inputclass="w-full"
                                                                    label="Nombre*"
                                                                    name="name"
                                                                    type="text"
                                                                    placeholder=""
                                                                />

                                                                <MyTextInput
                                                                    inputclass="w-full"
                                                                    label="Dirección*"
                                                                    name="address"
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                                <MyTextarea
                                                                    row={4}
                                                                    areaclass="w-full"
                                                                    label="Descripción"
                                                                    name="description"
                                                                    type="text"
                                                                    placeholder=""
                                                                />
                                                            </div>

                                                            <div className="flex justify-end p-3 bg-gray-50 text-center sm:px-6">
                                                                <button
                                                                    type="submit"
                                                                    className="justify-center flex gap-2 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                                                >
                                                                    <p className="">
                                                                        Enviar
                                                                    </p>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
