import {
  ProductInterface,
  RegionsInterface,
  StoreSections,
} from "../interfaces/ServerInterfaces";
import moment from "moment";
import { orderStatus } from "./staticData";
// import { orderStatus } from "./staticData";

export function printPrice(price: number | undefined) {
  if (!price) {
    return "0.00";
  }

  return price.toFixed(2);
}

export function printPriceFourDigits(price: number) {
  if (!price) {
    return "0.00";
  }

  return price.toFixed(4);
}
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
export const roundToTwoDecimal = (value: number) => {
  if (value === 0) {
    return 0;
  }
  return Math.ceil(value * 100) / 100;
};

export const roundToFourDecimal = (value: number) => {
  if (value === 0) {
    return 0;
  }
  return Math.round(value * 10000) / 10000;
};

export const getShowRemainQuantities = (value: number) => {
  let text = "";

  if (value <= 9 && value >= 1) {
    text = `Solo queda(n) ${value} en existencia.`;
  } else if (value >= 10 && value <= 50) {
    text = "Más de 10 en existencia.";
  } else if (value >= 51 && value <= 100) {
    text = "Más de 50 en existencia.";
  } else if (value > 100) {
    text = "Más de 100 en existencia.";
  } else {
    text = "Hay más unidades en camino";
  }

  return text;
};

export const getColorShowRemainQuantities = (value: number) => {
  let text = "";

  if (value <= 9 && value >= 1) {
    text = "text-red-400";
  } else if (value >= 10 && value <= 50) {
    text = "text-orange-600";
  } else if (value >= 51 && value <= 100) {
    text = "text-yellow-400";
  } else if (value > 100) {
    text = "text-green-500";
  } else {
    text = "text-blue-500";
  }

  return text;
};

export const generateUrlParams = (
  options?: Record<string, string | number | boolean | null>
) => {
  const list: string[] = [];
  if (options) {
    for (const [key, value] of Object.entries(options)) {
      if (!value) continue;
      list.push(`${key}=${value}`);
    }
  }
  if (list.length !== 0) {
    return "?" + list.join("&");
  } else {
    return "";
  }
};

export const calculateTotal = (
  subtotal: { prices: any; codeCurrency: any }[],
  shipping: { prices: any; codeCurrency: any }
) => {
  const total = subtotal.map((item) => ({ ...item })); // Crear una copia del arreglo subtotal para no modificarlo directamente

  // Sumar el valor de shiping.prices al valor correspondiente en subtotal si existe una coincidencia en el codeCurrency
  const shippingCurrencyIndex = total.findIndex(
    (item) => item.codeCurrency === shipping.codeCurrency
  );
  if (shippingCurrencyIndex !== -1) {
    total[shippingCurrencyIndex].prices += shipping.prices;
  } else {
    total.push(shipping); // Si no hay una coincidencia, agregar el objeto shipping al arreglo total
  }

  return total;
};

export function removeDuplicatesAndNulls(
  objects: (RegionsInterface | null)[]
): RegionsInterface[] {
  const uniqueObjects = objects.filter((obj, index, array) => {
    if (obj === null) {
      return false;
    }
    const currentIndex = array.findIndex(
      (item) => item !== null && item.name === obj.name
    );
    return currentIndex === index;
  }) as RegionsInterface[];

  return uniqueObjects;
}

export const normalizeString = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export const findMatchingProduct = (
  productName: string,
  array: Array<StoreSections>
): ProductInterface | undefined => {
  for (const section of array) {
    for (const data of section.data) {
      if (normalizeString(data.name).includes(normalizeString(productName))) {
        return data;
      }
    }
  }
  return undefined;
};

export const formatDateTime = (date: any, article?: any) => {
  const dateObj = moment(date).toObject();
  const todayObj = moment().toObject();

  const diffYear = Math.abs(dateObj.years - todayObj.years);
  const diffMonth = Math.abs(dateObj.months - todayObj.months);
  const diffDay = dateObj.date - todayObj.date;

  if (date) {
    if (diffYear === 0) {
      if (diffMonth === 0) {
        if (diffDay === 1) return moment(date).format("[Mañana a las] hh:mm A");
        if (diffDay === 0) return moment(date).format("[Hoy a las] hh:mm A");
        if (diffDay === -1) return moment(date).format("[Ayer a las] hh:mm A");
      }
      return moment(date).format(`${article ? "[el]" : ""} D [de] MMM`);
    }
    return moment(date).format(`${article ? "[el]" : ""} D [de] MMM YYYY`);
  }
  return "-";
};

export const filterOrdenStatus = (status: string) =>
  orderStatus.filter(
    (stts: { code: string; value: string; color: string }) =>
      stts.code === status
  )[0]?.value;

export const filterOrdenStatusColor = (status: string) =>
  orderStatus.filter(
    (stts: { code: string; value: string; color: string }) =>
      stts.code === status
  )[0]?.color;

export function scrollToAmount(amount: number) {
  window.scrollTo({
    top: window.scrollY + amount,
    left: 0,
    behavior: "smooth",
  });
}

export function scrollToSection(sectionId: string) {
  const section = document.getElementById(sectionId);

  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function printPriceWithCommasAndPeriods(price: unknown) {
  const roundedPrice = Number(price).toFixed(2);
  const formattedPrice = Number.isInteger(price)
    ? Number(price).toFixed(2)
    : roundedPrice;
  return formattedPrice.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const formatCalendarDetailsOrderWithoutHour = (
  date?: string | null,
  article?: boolean
) => {
  const momentDate = moment(date).utc(); // Parsear la fecha como UTC
  const todayObj = moment().toObject();

  const diffYear = Math.abs(momentDate.year() - todayObj.years);
  const diffMonth = Math.abs(momentDate.month() - todayObj.months);
  const diffDay = momentDate.date() - todayObj.date;

  if (date) {
    if (diffYear === 0) {
      if (diffMonth === 0) {
        if (diffDay === 1) return momentDate.format("[Mañana]");
        if (diffDay === 0) return momentDate.format("[Hoy]");
        if (diffDay === -1) return momentDate.format("[Ayer]");
      }
      return momentDate.format(`${article ? "[el]" : ""} D [de] MMM`);
    }
    return momentDate.format(`${article ? "[el]" : ""} DD/MM/YYYY`);
  }
  return "-";
};

export const translateOrderState = (status: string | null) => {
  switch (status) {
    case "CREATED":
      return "Creada";

    case "ACTIVE":
      return "Activa";

    case "CLOSED":
      return "Cerrada";

    case "DISPATCHED":
      return "Despachada";

    case "RECEIVED":
      return "Recibida";

    case "IN_PROCESS":
      return "Procesando";

    case "COMPLETED":
      return "Completada";

    case "PAYMENT_PENDING":
      return "Pendiente de pago";

    case "WAITING":
      return "En espera";

    case "CANCELLED":
      return "Cancelada";

    case "REFUNDED":
      return "Reembolsada";

    case "WITH_ERRORS":
      return "Con errores";

    case "BILLED":
      return "Pagada";

    case "IN_TRANSIT":
      return "En tránsito";

    case "DELIVERED":
      return "Entregada";

    case "PREFECTURE":
      return "Pre-facturada";

    case "PAID":
      return "Pagado";

    case "USED":
      return "Utilizado";

    case "OVERDUE":
      return "Pago vencido";

    default:
      return "";
  }
};
