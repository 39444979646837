import { createSlice } from "@reduxjs/toolkit";
import { OrderInterface, PaginateInterface } from "../../interfaces/ServerInterfaces";

interface InitialInterface {
  orders: OrderInterface[] | undefined;
  detailedOrders: OrderInterface[];
  ordersPaginate: PaginateInterface | null;
  orderCompleted: OrderInterface | null;
  bussinessBeforeCreateOrder: string | null;
  clearCarShop: boolean;
}

const initialState: InitialInterface = {
  orders: [],
  detailedOrders: [],
  ordersPaginate: null,
  orderCompleted: null,
  bussinessBeforeCreateOrder: null,
  clearCarShop: false,
};

const ordersSlice = createSlice({
  initialState,
  name: "orders",
  reducers: {
    addDetailedOrder: (state, action) => ({
      ...state,
      detailedOrders: [...state.detailedOrders, { ...action.payload }],
    }),
    setOrders: (state, action) => ({
      ...state,
      orders: action.payload
    }),
    setOrdersPaginate: (state, action) => ({
      ...state,
      ordersPaginate: action.payload
    }),
    setOrderCompleted: (state, action) => ({
      ...state,
      orderCompleted: action.payload
    }),
    setBussinessBeforeCreateOrder: (state, action) => ({
      ...state,
      bussinessBeforeCreateOrder: action.payload
    }),
    setClearCarShop: (state, action) => ({
      ...state,
      clearCarShop: action.payload
    }),
  },
});

export const { addDetailedOrder, setOrders, setOrdersPaginate, setOrderCompleted, setBussinessBeforeCreateOrder, setClearCarShop } = ordersSlice.actions;

export default ordersSlice.reducer;
